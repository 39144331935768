<template>
  <div class="kinto">
    <div class="login-grid">
      <div class="login-side">
        <img :src="require('assets/kinto/kinto_login_logo.png')" style="width: 80%" class="login-img">

        <router-view />

        <div class="login-footer" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus" scoped>
  .img-wrapper
    width 100%
  @import '../../../styles/login.styl'
  @media screen and (min-width: 641px)
    .login-grid
      background-image url('../../../assets/kinto/login.png')
      background-size:cover
  </style>
